@import '../variables/variables.css';

.jctextarea {
  font-size: 16px;
  padding: 8px 5px;
  border: var(--border-size) solid var(--colour-3);
  border-radius: 10px;
  outline: 0;
}

.jctextarea:focus {
  border: var(--border-size) solid var(--colour-4);
}

.jctextarea-container {
  display: flex;
  gap: 5px;
}

.jctextarea-container-top-label {
  flex-direction: column;
}

.jctextarea-container-left-label {
  flex-direction: row;
  align-items: center;
}
