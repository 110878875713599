.history-list-item {
  padding: 10px;
  border: var(--border-size) solid var(--colour-3);
  border-radius: 10px;
}

.history-list-item.selected {
  background-color: var(--colour-2);
  border: var(--border-size) solid var(--colour-2);
  color: white;
}
