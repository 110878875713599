.login {
  width: 300px;
  background-color: var(--colour-2);
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
}

.columns {
  display: grid;
  grid-template-columns: 100px 300px;
  text-align: left;
}

.centered {
  display: block;
  text-align: center;
}

.label-row,
.button-row {
  padding: 5px;
}

.button-parent {
  padding: 10px;
}

.button {
  width: 50%;
}

.error {
  background-color: var(--colour-1);
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  font-family: var(--fonts);
  color: var(--colour-error);
}
