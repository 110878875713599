@import '../variables/variables.css';

.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-inline-start: 0;
}

.list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--component-gap);
}
