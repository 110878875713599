.welcome-section {
  margin: 20px;
  padding: 20px;
  border: var(--border-size) solid #e0e0e0;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.welcome-section .title-text {
  font-size: 1.5rem;
  text-align: center;
}

.welcome-section .image {
  width: 300px;
  height: 300px;
}

.welcome-section .description {
  font-size: 1.2rem;
  text-align: justify;
}
