@import '../variables/variables.css';

.jctext {
  font-size: 16px;
  font-family: var(--font-family);
}

.jctext.bold {
  font-weight: 700;
}
