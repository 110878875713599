.red { background-color: red; }
.green { background-color: green; }
.blue { background-color: blue; }
.yellow { background-color: yellow; }

.red1 { background-color: #f00; }
.red2 { background-color: #c00; }
.red3 { background-color: #a00; }
.red4 { background-color: #800; }
.red5 { background-color: #600; }
.red6 { background-color: #400; }
.red7 { background-color: #200; }

.green1 { background-color: #0f0; }
.green2 { background-color: #0c0; }
.green3 { background-color: #0a0; }
.green4 { background-color: #080; }
.green5 { background-color: #060; }
.green6 { background-color: #040; }
.green7 { background-color: #020; }

.yellow1 { background-color: #ff0; }
.yellow2 { background-color: #cc0; }
.yellow3 { background-color: #aa0; }
.yellow4 { background-color: #880; }
.yellow5 { background-color: #660; }
.yellow6 { background-color: #440; }
.yellow7 { background-color: #220; }
