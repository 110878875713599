@import '../variables/variables.css';

.jcdropdown {
  font-size: 16px;
  font-family: var(--font-family);
  padding: 8px 5px;
  border: var(--border-size) solid var(--colour-3);
  border-radius: 10px;
  outline: 0;
}

.jcdropdown:focus {
  border: solid var(--border-size) var(--colour-4);
}

.jcdropdown-container {
  display: flex;
  gap: 5px;
}

.jcdropdown-container-top-label {
  flex-direction: column;
}

.jcdropdown-container-left-label {
  flex-direction: row;
  align-items: center;
}
