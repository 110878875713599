/*
FIXME Various fonts to try out:
@import url('https://fonts.googleapis.com/css2?family=Handjet&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Doppio+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anaheim&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+2:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lekton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Duru+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bellota+Text:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta+Vaani:wght@200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inder&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Homenaje&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta+Vaani:wght@200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@100;200;300;400;500;600;700&display=swap');
*/

@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@200;300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
}

html {
  --colour-1: #282833;
  --colour-2: #4c5871;
  --colour-3: #6a7e8c;
  --colour-4: #93aaba;
  --colour-5: #e8c5a0;
  --colour-6: #f6e8c6;
  --colour-7: #fffaed;
  --colour-error: #ef2727;
  --font-family: "Anek Malayalam",sans-serif;
  --component-gap: 10px;
  --border-size: 1px;
}

body, button, input {
  font-family: var(--font-family);
}
