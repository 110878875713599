@import '../variables/variables.css';

.flex {
  display: flex;
}

.columns, .cols {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  gap: var(--component-gap);
}

.rows {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  gap: var(--component-gap);
}

.no-wrap {
  flex-wrap: nowrap;
}

/* FIXME Using flex instead of flex-grow here as it's recommended */
.small-col, .small-row {
  flex: 0;
}

/* Small column with a fixed-width */
.small-col-25em {
  flex: 0 0 25em;
}
.small-col-12em {
  flex: 0 0 12em;
}

.big-col, .big-row {
  flex: 1;
}

.full-width, .width-100 {
  width: 100%;
}

.width-60 {
  width: 60%;
}

.width-50 {
  width: 50%;
}

.width-40 {
  width: 40%;
}

.height-100pc {
  height: 100%;
}

.height-50vh {
  height: 50vh;
}

.align-centre {
  align-items: center;
}

.centre {
  text-align: center;
}

.cc {
  justify-content: center;
  align-items: center;
}
