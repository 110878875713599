.padding-all-0px { padding: 0px; }
.padding-top-0px { padding-top: 0px; }
.padding-right-0px { padding-right: 0px; }
.padding-bottom-0px { padding-bottom: 0px; }
.padding-left-0px { padding-left: 0px; }

.padding-all-10px { padding: 10px; }
.padding-top-10px { padding-top: 10px; }
.padding-right-10px { padding-right: 10px; }
.padding-bottom-10px { padding-bottom: 10px; }
.padding-left-10px { padding-left: 10px; }

.padding-all-20px { padding: 20px; }
.padding-top-20px { padding-top: 20px; }
.padding-right-20px { padding-right: 20px; }
.padding-bottom-20px { padding-bottom: 20px; }
.padding-left-20px { padding-left: 20px; }
