@import '../variables/variables.css';

.design-system-preview-layout {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  gap: var(--component-gap);

  padding: 20px;
}

.item {
  flex: 0;
}

.design-system-preview-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  gap: 30px;
}

.design-system-preview-label {
  padding-bottom: 10px;
}

.odd {
  background-color: white;
}

.even {
  background-color: #eee;
}
