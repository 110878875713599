.navbar {
  padding: 1rem 3rem;
  background-color: #333;
  display: flex;
  justify-content: space-between;
}

.navbar-brand {
  font-size: 1.5rem;
  color: #ff6347;
  text-decoration: none;
}

.nav-link {
  margin-right: 1rem;
  color: #fff;
  transition: all 0.3s ease;
  text-decoration: none;
}

.nav-link:hover {
  color: #ff6347;
}

.nav-links {
  display: flex;
}
