.xchat-window-container {
  display: flex;
  flex-direction: column;
  width: 82%;
  height: 80vh;
  margin: 0px 5px;
}

.chat-window-messages {
  overflow-y: scroll;
  scrollbar-gutter: auto;
}

.fake-field {
  padding: 10px;
  border: var(--border-size) solid var(--colour-3);
  border-radius: 10px;
}

.xchat-window-form {
  display: flex;
  margin-bottom: 10px;
}

/* FIXME Replace error message with an ErrorMessage component */
.error-message {
  background-color: #d00;
}
