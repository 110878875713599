.margin-all-0px { margin: 0px; }
.margin-top-0px { margin-top: 0px; }
.margin-right-0px { margin-right: 0px; }
.margin-bottom-0px { margin-bottom: 0px; }
.margin-left-0px { margin-left: 0px; }

.margin-all-10px { margin: 10px; }
.margin-top-10px { margin-top: 10px; }
.margin-right-10px { margin-right: 10px; }
.margin-bottom-10px { margin-bottom: 10px; }
.margin-left-10px { margin-left: 10px; }
