.label {
  font-family: var(--fonts);
  font-size: 16px;
  font-weight: 600;
  color: var(--colour-4);
}

.dark-text {
  color: var(--colour-1);
}

.xfield {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--fonts);
  padding: 5px;
  border: var(--border-size) solid var(--colour-1);
  border-radius: 10px;
}

.login-field {
  margin-top: 10px;
  width: 275px;
}
