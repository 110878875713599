@import './css/base-style.css';
@import './css/field.css';
@import './Login.css';
@import './AppContainer.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.dropdown-container {
  position: absolute;
  right: 0;
  top: 0;  /* adjust as needed */
  margin-right: 20px; /* adjust as needed */
}

.space-element {
  margin-bottom: 20px;  /* adjust as necessary */
}

.app-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.app-container {
  display: flex;
  flex-direction: column;
}

.app-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chat-window {
  width: 100%;
  flex: 2;
  /* Adjust according to your needs */
}

.controls {
  flex: 100;
  /* Adjust according to your needs */
  display: flex;
  flex-direction: column;
}

.space-element-small {
  width: 80px; /* or any other value */
  height: 30px; /* or any other value */
  padding: 5px 10px; /* vertical padding 5px, horizontal padding 10px */
  font-size: 0.8rem; /* or any other value */
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* This will help in vertically centering the contents inside */
  min-height: calc(100vh - 60px);; /* This makes sure the container takes the full viewport height */
}

.login-header {
  font-size: 2em; /* Adjust this value as needed to increase the font size */
  text-align: center;
  margin-bottom: 20px; /* This will give some spacing between the header and the login form. Adjust as needed. */
}
