.modal-background {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.5);
}

.modal-container {
  background-color: white;
  margin: 10% auto;
  width: 50%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
}

.modal-header {
  flex-grow: 0;
  padding: 5px;
}

.modal-header-contents {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.modal-header-title {
  flex-grow: 1;
  font-weight: 600;
  font-size: 20px;
}

.modal-header-close {
  cursor: pointer;
  flex-grow: 0;
  font-size: 20px;
  font-weight: 600;
}

.modal-content {
  flex-grow: 2;
  padding: 5px;
}

.modal-footer {
  flex-grow: 0;
  padding: 5px;
}

.modal-footer-contents {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  gap: 5px;
}
