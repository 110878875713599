.message {
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}

.message.default, .message.info {
  background-color: #a3b1ff;
  border: var(--border-size) solid #717ab0;
}

.message.success {
  background-color: #9f9;
  border: var(--border-size) solid #1d1;
}

.message.warning {
  background-color: #fffc63;
  border: var(--border-size) solid #ffb339;
}

.message.error {
  background-color: #a00;
  border: var(--border-size) solid #c22;
  color: white;
}

.message.centre-text {
  text-align: center;
}
