@import '../variables/variables.css';

.jcbutton {
  font-size: 16px;
  font-weight: 400;
  background-color: var(--colour-2);
  border: var(--border-size) solid black;
  border-radius: 10px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
}

.jcbutton:hover {
  background-color: var(--colour-3);
}
